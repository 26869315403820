import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import {
    Box, Paragraph, Heading, Text, Button, Table, TableHeader, TableRow, TableBody, TableCell, Image, Anchor, Header,
    Tabs, Tab, Accordion, AccordionPanel, TextInput, Layer, Card, CardBody, Tip, DataTable, RadioButtonGroup, CardFooter,
    Form, FormField, CheckBoxGroup, DateInput
} from 'grommet';
import { SubtractCircle, Search, CircleInformation, DocumentDownload, Filter, FormClose } from 'grommet-icons';
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import { retrieveCourseware, downloadDocument } from '../store/CertificationInstructorConnection'
import { retrieveTrainingEvents, retrieveInstructor, saveAffiliationRequest, retrieveLearningPartners, saveInstructor } from '../store/LearningPartnerConnection';
import BasePage from '../components/BasePage';
import _ from 'lodash';
import { Courseware } from '../models/cic/Courseware';
import { format, parseISO, formatISO } from 'date-fns';
import HtmlComponent from '../components/HtmlComponent';
import { hasRole } from '../Utilities';
import AffiliationRequest from '../components/AffiliationRequest';
import { AffiliationRequest as RequestModel } from '../models/lpc/AffiliationRequest'
import { InstructorAffiliation } from '../models/lpc/InstructorAffiliation';
import { Instructor } from '../models/lpc/Instructor';
import { InstructorAuthorization } from '../models/lpc/InstructorAuthorization';

const mapDispatchToProps = {
    push,
    retrieveLocalizedStrings,
    retrieveCourseware,
    retrieveTrainingEvents,
    retrieveInstructor,
    saveInstructor,
    saveAffiliationRequest,
    retrieveLearningPartners,
    downloadDocument,
    ...siteSlice.actions,
}

interface ICertificationInstructorConnectionProps {
}

interface ICertificationInstructorConnectionState {
    activeTab: number;
    showNotification: boolean;
    instructorLoaded: boolean;
    addAffiliation: boolean;
    reloaded: boolean;
    reloadInstructor: boolean;
    courseware?: Courseware[];
    showFilters: boolean;
    keywords?: string;
    filters?: any;
    advancedFilters?: any;
    filterTechnologies?: string[];
    numberOfTabs?: number;
}

type CertificationInstructorConnectionProps = ICertificationInstructorConnectionProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class CertificationInstructorConnection extends React.PureComponent<CertificationInstructorConnectionProps, ICertificationInstructorConnectionState> {

    constructor(props: CertificationInstructorConnectionProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let tab = this.props.match
            ? this.props.match.params.activeTab
                ? this.props.match.params.activeTab
                : null
            : null;
        let activeTab = 0;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.CicKeywords', 'Metadata.CicDescription', 'ExternalPages.Dashboard_Label',
            'ExternalPages.Materials_Label', 'ExternalPages.CIC_Title_Statement', 'ExternalPages.CIC_Statement', 'ExternalPages.Instrctor_Programs_Label',
            'ExternalPages.Affiliations_Label', 'ExternalPages.Instructor_Relationship_Status_Label', 'ExternalPages.Certified_Instructor_Authorizations_Label',
            'ExternalPages.CIC_Affiliations_Tooltip', 'ExternalPages.CIC_IRS_AvailableToAll_Label', 'ExternalPages.CIC_IRS_AvailableToAll_Statement',
            'ExternalPages.CIC_IRS_Exclusive_Label', 'ExternalPages.CIC_IRS_Exclusive_Statement', 'ExternalPages.CIC_IRS_Employee_Label',
            'ExternalPages.CIC_IRS_Employee_Statement', 'ExternalPages.CIC_IRS_Tooltip_Title', 'Datacard.Training_Label', 'ExternalPages.Unaffiliate_Label',
            'ExternalPages.Lookup_LearnerId_Label', 'ExternalPages.CIC_IRS_Sales_Status_Label', 'ExternalPages.CIC_IRS_Technical_Status_Label',
            'ExternalPages.CIC_IRS_Aruba_Status_Label', 'ExternalPages.Instructor_Training_Events_Label', 'ExternalPages.Instructor_Training_Events_Statement',
            'ExternalPages.InstructorType_Label', 'ExternalPages.Cancel_Label', 'ExternalPages.Submit_Label', 'ExternalPages.CIC_AddAffiliation_Label',
            'ExternalPages.LPC_AffiliationRequest_Instruction', 'ExternalPages.Learning_Partner_Label', 'ExternalPages.RelationshipType_Label',
            'ExternalPages.LPC_ManageAffiliations_NoAffiliations_Label', 'ExternalPages.Pending_Label', 'Datacard.Course_Id_Label',
            'ExternalPages.Course_Title_Label', 'ExternalPages.Certification_Grant_Date_Label', 'ExternalPages.Certification_Valid_Until_Date_Label',
            'ExternalPages.Courseware_Filters_Label', 'ExternalPages.Instrutor_Label', 'ExternalPages.Search_Label', 'ExternalPages.Last_Updated_Label',
            'MyLearning.Type_Label', 'ExternalPages.LastUpdatedRange_Label', 'ExternalPages.Package_Label', 'ExternalPages.All_Course_Files_Label',
            'ExternalPages.DataSheet_Label', 'ExternalPages.EBook_Label', 'ExternalPages.Delivery_Readiness_Documents_Label', 'ExternalPages.FacilitatorGuide_Label',
            'ExternalPages.StudentGuide_Label', 'ExternalPages.LabGuide_Label', 'ExternalPages.InstructorSlides_Label', 'ExternalPages.CourseOutline_Label',
            'ExternalPages.ResetFilters_Label', 'ExternalPages.ApplyFilters_Label', 'ExternalPages.Administrator_Label', 'MyLearning.Not_Authorized_Statement',
            'ExternalPages.Certified_Instructor_NoAuthorizations_Label'];

        switch (tab) {
            case 'materials':
                activeTab = 1;
                break;
            case 'training':
                activeTab = 2;
                break;
        }
        this.state = {
            activeTab: activeTab,
            showNotification: false,
            instructorLoaded: false,
            addAffiliation: false,
            reloaded: false,
            reloadInstructor: false,
            showFilters: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveCourseware();
        this.props.retrieveTrainingEvents();
        this.props.retrieveLearningPartners();
        this.props.setActiveMenu('resources-menu');
    }

    public componentDidUpdate() {
        let site = this.props.site;
        let userInfo = site.userInfo;
        let salesLabel = site.localizedStrings['ExternalPages.CIC_IRS_Sales_Status_Label'];
        let user = site.user;
        let isCicAdmin = hasRole(user, ['CIC_ADMIN']);

        // reloadInstructor and reloaded are used for swtich user functionality
        if (userInfo && ((!this.props.lpc.instructor && !this.state.instructorLoaded) || (this.state.reloadInstructor && !site.loadingUser))) {
            this.setState({ instructorLoaded: true, reloadInstructor: false, reloaded: site.actualUser !== undefined });
            this.props.retrieveInstructor();
        }
        if (site.actualUser && this.state.instructorLoaded && !this.state.reloadInstructor && !this.state.reloaded && !site.loadingUser) {
            this.setState({ reloadInstructor: true, numberOfTabs: undefined });
        }
        if (!site.actualUser && this.state.reloaded && !site.loadingUser && !this.state.reloadInstructor) {
            this.setState({ reloadInstructor: true, numberOfTabs: undefined });
        }
        if ((!this.state.filterTechnologies || this.state.filterTechnologies.length == 0) &&
            this.props.cic.courseware && this.props.cic.courseware.length !== 0) {
            let filterTechnologies = _.flatMap(_.filter(this.props.cic.courseware, c => !c.course.isSales), c => c.course.technologies);
            let hasSales = _.some(this.props.cic.courseware, c => c.course.isSales);

            if (hasSales) {
                filterTechnologies.push(salesLabel);
            }
            filterTechnologies = _.uniq(filterTechnologies);

            this.setState({
                filterTechnologies: filterTechnologies,
                advancedFilters: {
                    type: filterTechnologies
                }
            });
        }
        if (user && !this.state.numberOfTabs) {
            this.setState({ numberOfTabs: isCicAdmin ? 2 : 3 });
            setTimeout(() => {
                this.onActivateTab(isCicAdmin ? 1 : 0);
            }, 100);
        }
    }

    onActivateTab = (activeTab: number) => {
        this.setState({ activeTab: activeTab });
        switch (activeTab) {
            case 1:
                this.props.push('/cic/materials');
                break;
            case 2:
                this.props.push('/cic/training');
                break;
            default: {
                this.props.push('/cic');
                break;
            }
        }
    }

    onDownload = (courseware: Courseware, document: string) => {
        this.props.downloadDocument(courseware, document);
    }

    getMaterialPanel = (courseware: Courseware, localUrl: string) => {
        let course = courseware.course;
        let site = this.props.site;

        return <AccordionPanel key={`course-pnl-${course.number}`} label={
            <Box pad="small" gap="xxsmall">
                <Text size="small">{`Course ${course.number} - ${format(parseISO(course.launchDate), 'MMM-dd-yyyy')}`}</Text>
                <Text weight="bold" >{course.name}</Text>
            </Box>}>
            <Box gap="small" pad={{ left: "small" }}>
                <Anchor label={`${course.name}`} href={`${localUrl}/datacard/course/${course.number}`} />
                <Table className='striped'>
                    <TableHeader>
                        <TableRow>
                            <TableCell />
                            <TableCell><Text>{site.localizedStrings['MyLearning.Type_Label']}</Text></TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.Last_Updated_Label']}</Text></TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />} onClick={() => this.onDownload(courseware, "AllFiles")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.All_Course_Files_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.packageDate
                                        ? format(parseISO(courseware.packageDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}</Text>
                            </TableCell>
                        </TableRow>
                        {courseware.package && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />} onClick={() => this.onDownload(courseware, "Package")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.Package_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.packageDate
                                        ? format(parseISO(courseware.packageDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}</Text>
                            </TableCell>
                        </TableRow>}
                        {courseware.dataSheet && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />} onClick={() => this.onDownload(courseware, "DataSheet")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.DataSheet_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.dataSheetDate
                                        ? format(parseISO(courseware.dataSheetDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}</Text>
                            </TableCell>
                        </TableRow>}
                        {courseware.eBook && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />}
                                    onClick={() => this.onDownload(courseware, "EBook")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.EBook_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.eBookDate
                                        ? format(parseISO(courseware.eBookDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}</Text>
                            </TableCell>
                        </TableRow>}
                        {courseware.outline && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />}
                                    onClick={() => this.onDownload(courseware, "Outline")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.CourseOutline_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.outlineDate
                                        ? format(parseISO(courseware.outlineDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}
                                </Text>
                            </TableCell>
                        </TableRow>}
                        {courseware.deliveryReadinessDocument && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />}
                                    onClick={() => this.onDownload(courseware, "DeliveryReadinessDocument")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.Delivery_Readiness_Documents_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.drdDate
                                        ? format(parseISO(courseware.drdDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}
                                </Text>
                            </TableCell>
                        </TableRow>}
                        {courseware.facilitatorGuide && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />}
                                    onClick={() => this.onDownload(courseware, "FacilitatorGuide")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.FacilitatorGuide_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.facilitatorGuideDate
                                        ? format(parseISO(courseware.facilitatorGuideDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}
                                </Text>
                            </TableCell>
                        </TableRow>}
                        {courseware.instructorSlides && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />}
                                    onClick={() => this.onDownload(courseware, "InstructorSlides")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.InstructorSlides_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.instructorSlidesDate
                                        ? format(parseISO(courseware.instructorSlidesDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}
                                </Text>
                            </TableCell>
                        </TableRow>}
                        {courseware.labConfigurationGuide && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />}
                                    onClick={() => this.onDownload(courseware, "LabConfigurationGuide")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.LabConfigurationGuide_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.labConfigurationGuideDate
                                        ? format(parseISO(courseware.labConfigurationGuideDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}
                                </Text>
                            </TableCell>
                        </TableRow>}
                        {courseware.labGuide && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />} onClick={() => this.onDownload(courseware, "LabGuide")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.LabGuide_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.labGuideDate
                                        ? format(parseISO(courseware.labGuideDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}
                                </Text>
                            </TableCell>
                        </TableRow>}
                        {courseware.studentGuide && <TableRow>
                            <TableCell>
                                <Button icon={<DocumentDownload size='small' />} onClick={() => this.onDownload(courseware, "StudentGuide")} />
                            </TableCell>
                            <TableCell><Text>{site.localizedStrings['ExternalPages.StudentGuide_Label']}</Text></TableCell>
                            <TableCell>
                                <Text>
                                    {courseware.studentGuideDate
                                        ? format(parseISO(courseware.studentGuideDate), 'yyyy-MMM-dd  hh:mm:ss')
                                        : ''}
                                </Text>
                            </TableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
            </Box>
        </AccordionPanel>;
    }

    onAddAfilliation = () => {
        this.setState({ addAffiliation: true });
    }

    onSaveAffiliation = (request: RequestModel) => {
        this.props.saveAffiliationRequest(request, this.onAffiliationSave);
    }

    onCancelAddAffiliation = () => {
        this.setState({ addAffiliation: false });
    }

    onAffiliationSave = () => {
        this.setState({ addAffiliation: false });
    }

    onUnaffiliate = (selected: InstructorAffiliation) => {
        let instructor = JSON.parse(JSON.stringify(this.props.lpc.instructor)) as Instructor;
        let affiliation = JSON.parse(JSON.stringify(selected)) as InstructorAffiliation;
        let site = this.props.site;
        let userInfo = site.userInfo;

        affiliation.unAffiliatedBy = {
            email: userInfo?.email as string,
            name: userInfo?.name as string
        };
        affiliation.unAffiliationDate = formatISO(new Date());
        affiliation.unAffiliationReason = 'Removed by instructor';
        _.remove(instructor.affiliations, (aff) => aff.instructorType == affiliation.instructorType &&
            aff.learningPartner.id == affiliation.learningPartner.id);
        instructor.affiliations.push(affiliation);

        this.props.saveInstructor(instructor);
    }

    onShowFilters = () => {
        this.setState({ showFilters: true });
    }

    onHideFilters = () => {
        this.setState({ showFilters: false });
    }

    filterByKeywords = (keywords: string) => {
        let courseware = this.props.cic.courseware;

        if (keywords) {
            let containsKeywords = keywords.toLowerCase();
            let exactKeywords = keywords.replaceAll('"', '');

            courseware = keywords.startsWith('"') && keywords.endsWith('"')
                ? _.filter(courseware, (cw) => cw.course.name === exactKeywords ||
                    cw.course.number === exactKeywords)
                : _.filter(courseware, (cw) => cw.course.name.toLowerCase().indexOf(containsKeywords) !== -1 ||
                    cw.course.number.toLowerCase().indexOf(containsKeywords) !== -1);
        }
        return courseware;
    }

    onApplyFilters = () => {
        let site = this.props.site;
        let filters = this.state.advancedFilters;
        let keywords = this.state.filters?.keywords;
        let courseware: Courseware[] = this.filterByKeywords(keywords);
        let salesLabel = site.localizedStrings['ExternalPages.CIC_IRS_Sales_Status_Label'];

        this.setState({ courseware: [] });
        setTimeout(() => {
            if (filters.type) {
                let technicalCourseware = _.filter(courseware, c => !c.course.isSales);
                let salesCourseware = _.filter(courseware, c => c.course.isSales);

                courseware = _.flatMap(filters.type, type => {
                    let filtered = type === salesLabel
                        ? salesCourseware
                        : _.filter(technicalCourseware, tcw => _.some(tcw.course.technologies, tech => tech === type));

                    return filtered;
                });
            }
            if (filters.updateDate) {
                let startDate = parseISO(filters.updateDate[0]);
                let endDate = parseISO(filters.updateDate[1]);

                courseware = _.filter(courseware, (cw) => {
                    let packageDate = cw.packageDate ? parseISO(cw.packageDate) : undefined;
                    let dataSheetDate = cw.dataSheetDate ? parseISO(cw.dataSheetDate) : undefined;
                    let eBookDate = cw.eBookDate ? parseISO(cw.eBookDate) : undefined;
                    let outlineDate = cw.outlineDate ? parseISO(cw.outlineDate) : undefined;
                    let drdDate = cw.drdDate ? parseISO(cw.drdDate) : undefined;
                    let facilitatorGuideDate = cw.facilitatorGuideDate ? parseISO(cw.facilitatorGuideDate) : undefined;
                    let instructorSlidesDate = cw.instructorSlidesDate ? parseISO(cw.instructorSlidesDate) : undefined;
                    let labConfigurationGuideDate = cw.labConfigurationGuideDate ? parseISO(cw.labConfigurationGuideDate) : undefined;
                    let labGuideDate = cw.labGuideDate ? parseISO(cw.labGuideDate) : undefined;
                    let studentGuideDate = cw.studentGuideDate ? parseISO(cw.studentGuideDate) : undefined;

                    return (packageDate !== undefined && packageDate >= startDate && packageDate <= endDate) ||
                        (dataSheetDate !== undefined && dataSheetDate >= startDate && dataSheetDate <= endDate) ||
                        (eBookDate !== undefined && eBookDate >= startDate && eBookDate <= endDate) ||
                        (outlineDate !== undefined && outlineDate >= startDate && outlineDate <= endDate) ||
                        (drdDate !== undefined && drdDate >= startDate && drdDate <= endDate) ||
                        (facilitatorGuideDate !== undefined && facilitatorGuideDate >= startDate && facilitatorGuideDate <= endDate) ||
                        (instructorSlidesDate !== undefined && instructorSlidesDate >= startDate && instructorSlidesDate <= endDate) ||
                        (labConfigurationGuideDate !== undefined && labConfigurationGuideDate >= startDate && labConfigurationGuideDate <= endDate) ||
                        (labGuideDate !== undefined && labGuideDate >= startDate && labGuideDate <= endDate) ||
                        (studentGuideDate !== undefined && studentGuideDate >= startDate && studentGuideDate <= endDate);
                });
            }

            this.setState({ courseware: courseware, showFilters: false });
        }, 250);
    }

    onResetFilters = () => {
        let keywords = this.state.filters?.keywords;
        let courseware: Courseware[] = this.filterByKeywords(keywords);

        this.setState({
            advancedFilters: {
                type: this.state.filterTechnologies
            },
            courseware: courseware,
            showFilters: false
        });
    }

    onKeywordsChange = (event: any) => {
        let keywords = event.target.value;

        this.setState({ courseware: undefined });
        setTimeout(() => {
            let courseware: Courseware[] = this.filterByKeywords(keywords);

            this.setState({ courseware: courseware });
        }, 50);
    }

    onDeselectTypes = () => {
        this.setState({
            advancedFilters: {
                type: []
            }
        });
    }

    public render() {
        let site = this.props.site;
        let user = site.user;
        let userInfo = site.userInfo;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let languageUrl = langInfo[0] !== 'en'
            ? `/${langInfo[0]}`
            : '';
        let page = site.menuStrings['ExternalPages.CertifiedInstructorConnection_Label'];
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['Datacard.Resources_Label'], value: "" },
            { key: page, value: "" }
        ];
        let statusOptions = [
            `${site.localizedStrings['ExternalPages.CIC_IRS_AvailableToAll_Label']}`,
            `${site.localizedStrings['ExternalPages.CIC_IRS_Exclusive_Label']}`,
            `${site.localizedStrings['ExternalPages.CIC_IRS_Employee_Label']}`
        ]
        let courseware = this.state.courseware ?? this.props.cic.courseware;
        let technicalCourseware = _.filter(courseware, c => !c.course.isSales);
        let salesCourseware = _.filter(courseware, c => c.course.isSales);
        let technologies = _.uniq(_.flatMap(technicalCourseware, c => c.course.technologies));
        let trainingEvents = this.props.lpc.trainingEvents;
        let emailRegexp = /hpe\.com/i;
        let isEmployee = emailRegexp.test(user?.email as string);
        let isCicAdmin = hasRole(user, ['CIC_ADMIN']);
        let instructor = this.props.lpc.instructor;
        let isInstructor = hasRole(user, ['INSTRUCTOR', 'CERTIFIED_INSTRUCTOR']) || (instructor !== undefined && instructor != null);
        let affiliations = _.filter(instructor?.affiliations, affiliation =>
            (affiliation.denialReason === undefined || affiliation.denialReason === null) &&
            (affiliation.unAffiliatedBy === undefined || affiliation.unAffiliatedBy === null));
        let approvedAffiliations = _.filter(affiliations, affiliation => affiliation.approved);
        let salesLabel = site.localizedStrings['ExternalPages.CIC_IRS_Sales_Status_Label'];
        let techLabel = site.localizedStrings['ExternalPages.CIC_IRS_Technical_Status_Label'];
        let arubaLabel = site.localizedStrings['ExternalPages.CIC_IRS_Aruba_Status_Label'];
        let relationships = _.map(_.groupBy(approvedAffiliations, affiliation => affiliation.instructorType), (relationshipItems, key) => {
            return {
                name: key,
                count: relationshipItems.length
            }
        });
        let hasAffiliations = affiliations.length !== 0;
        let salesRelationship = _.find(relationships, rship => rship.name === salesLabel);
        let technicalRelationship = _.find(relationships, rship => rship.name === techLabel);
        let arubaRelationship = _.find(relationships, rship => rship.name === arubaLabel);
        let salesStatus = salesRelationship === undefined
            ? ""
            : isEmployee
                ? `${site.localizedStrings['ExternalPages.CIC_IRS_Employee_Label']}`
                : salesRelationship?.count === 1
                    ? `${site.localizedStrings['ExternalPages.CIC_IRS_Exclusive_Label']}`
                    : `${site.localizedStrings['ExternalPages.CIC_IRS_AvailableToAll_Label']}`;
        let arubaStatus = arubaRelationship === undefined
            ? ""
            : isEmployee
                ? `${site.localizedStrings['ExternalPages.CIC_IRS_Employee_Label']}`
                : arubaRelationship?.count === 1
                    ? `${site.localizedStrings['ExternalPages.CIC_IRS_Exclusive_Label']}`
                    : `${site.localizedStrings['ExternalPages.CIC_IRS_AvailableToAll_Label']}`;
        let techinalStatus = technicalRelationship === undefined
            ? ""
            : isEmployee
                ? `${site.localizedStrings['ExternalPages.CIC_IRS_Employee_Label']}`
                : technicalRelationship?.count === 1
                    ? `${site.localizedStrings['ExternalPages.CIC_IRS_Exclusive_Label']}`
                    : `${site.localizedStrings['ExternalPages.CIC_IRS_AvailableToAll_Label']}`;
        let authorizations = instructor?.authorizations;
        let hasAuthorizations = authorizations?.length !== 0;
        let filterTechnologies = this.state.filterTechnologies ?? [];
        let maxLength = filterTechnologies?.length ?? 0;
        let splitLength = maxLength > 5 ? Math.round(maxLength / 2) : 5;

        if (!user) {
            return null;
        }
        if (user && !isInstructor && !isCicAdmin) {
            return <Box align="center" justify="center" pad={{ top: 'large', bottom: "large" }}>
                <Text size='large' textAlign="center" weight="bold">{site.localizedStrings['MyLearning.Not_Authorized_Statement']}</Text>
            </Box>;
        }
        if (salesCourseware.length !== 0) {
            technologies.push(salesLabel);
            technologies = _.uniq(technologies);
        }

        return <BasePage breadcrumbs={breadcrumbs} title={page} pageName="CertificationInstructorConnection" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.PlmtKeywords']}
            metadataDescription={site.localizedStrings['Metadata.PlmtDescription']} >
            {site.stringsLoaded && <Box gap="medium" pad={{ bottom: 'small' }}>
                <Heading level={1} textAlign="center" fill>{page}</Heading>
                <Box direction="row-responsive" gap="small">
                    {!isCicAdmin && <Text weight="bold">{`${site.localizedStrings['ExternalPages.Instrutor_Label']}:`}</Text>}
                    {isCicAdmin && <Text weight="bold">{`${site.localizedStrings['ExternalPages.Administrator_Label']}:`}</Text>}
                    <Text>{`${user.firstName} ${user.lastName}`}</Text>
                </Box>
                <Box gap="small">
                    <Heading level={4}>{site.localizedStrings['ExternalPages.CIC_Title_Statement']}</Heading>
                    <Box width="large">
                        <Paragraph fill>{site.localizedStrings['ExternalPages.CIC_Statement']}</Paragraph>
                    </Box>
                </Box>
                <Tabs activeIndex={this.state.activeTab} onActive={this.onActivateTab} justify="center">
                    {!isCicAdmin && <Tab title={String(site.localizedStrings['ExternalPages.Dashboard_Label'])} >
                        <Box gap="medium" pad="small">
                            <Box gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.Instrctor_Programs_Label']}</Heading>
                                <Box direction="row-responsive" gap="small">
                                    <Card>
                                        <CardBody fill align="center">
                                            <Image src={instructor?.isSalesCertified
                                                ? 'images/cic/HPEInstructorBadge-Color.png'
                                                : 'images/cic/HPEInstructorBadge-Gray.png'} />
                                        </CardBody>
                                        <CardFooter justify="center">
                                            <Text weight="bold" textAlign="center">HPE Certified Sales Instructor</Text>
                                        </CardFooter>
                                    </Card>
                                    <Card>
                                        <CardBody fill align="center">
                                            <Image src={instructor?.isTechnicalCertified
                                                ? 'images/cic/HPEInstructorBadge-Color.png'
                                                : 'images/cic/HPEInstructorBadge-Gray.png'} />
                                        </CardBody>
                                        <CardFooter justify="center">
                                            <Text weight="bold">HPE Certified Technical Instructor</Text>
                                        </CardFooter>
                                    </Card>
                                    <Card>
                                        <CardBody fill align="center">
                                            <Image src={instructor?.isArubaCertified
                                                ? 'images/cic/ArubaInstructorBadge-Color.png'
                                                : 'images/cic/ArubaInstructorBadge-Gray.png'} />
                                        </CardBody>
                                        <CardFooter justify="center">
                                            <Text weight="bold">HPE Aruba Certified Instructor</Text>
                                        </CardFooter>
                                    </Card>
                                </Box>
                            </Box>
                            <Box gap="small" width="large">
                                <Box direction="row-responsive" gap="xsmall">
                                    <Heading level={4}>{site.localizedStrings['ExternalPages.Affiliations_Label']}</Heading>
                                    <Tip dropProps={{ align: { top: "top", left: "right" } }} content={<Box width="medium" pad="small" align="end">
                                        <Paragraph fill>{`${site.localizedStrings['ExternalPages.CIC_Affiliations_Tooltip']}`}</Paragraph>
                                    </Box>}>
                                        <Button margin={{ left: "small" }}>
                                            <CircleInformation size="small" color="brand" style={{ marginBottom: "6px" }} />
                                        </Button>
                                    </Tip>
                                </Box>
                                <Box gap="small">
                                    <Box width="small">
                                        <Button primary label={site.localizedStrings['ExternalPages.CIC_AddAffiliation_Label']} onClick={this.onAddAfilliation} />
                                    </Box>
                                    <Box>
                                        {!hasAffiliations && !site.loading && <Box fill="horizontal" pad={{ left: "small" }}>
                                            <Text>{site.localizedStrings['ExternalPages.LPC_ManageAffiliations_NoAffiliations_Label']}</Text>
                                        </Box>}
                                        {hasAffiliations && <DataTable fill pin="header" data={affiliations} columns={[
                                            {
                                                property: '',
                                                header: '',
                                                render: (datum: InstructorAffiliation) => <Box>
                                                    {datum.approved && <Button icon={<SubtractCircle />} onClick={() => { this.onUnaffiliate(datum); }}
                                                        tip={site.localizedStrings['ExternalPages.Unaffiliate_Label']} />}
                                                    {!datum.approved && <Text >{site.localizedStrings['ExternalPages.Pending_Label']}</Text>}
                                                </Box>
                                            },
                                            {
                                                property: 'learningPartner.name',
                                                header: site.localizedStrings['ExternalPages.Learning_Partner_Label']
                                            },
                                            {
                                                property: 'instructorType',
                                                header: site.localizedStrings['ExternalPages.InstructorType_Label']
                                            }]} />}
                                    </Box>
                                </Box>
                            </Box>
                            <Box gap="small">
                                <Box direction="row-responsive" gap="xsmall">
                                    <Heading level={4}>{site.localizedStrings['ExternalPages.Instructor_Relationship_Status_Label']}</Heading>
                                    <Tip dropProps={{ align: { top: "bottom", left: "right" } }} content={<Box width="medium" pad="small" gap="medium">
                                        <Box gap="small">
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.CIC_IRS_AvailableToAll_Label']}</Text>
                                            <Paragraph fill>{site.localizedStrings['ExternalPages.CIC_IRS_AvailableToAll_Statement']}</Paragraph>
                                        </Box>
                                        <Box gap="small">
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.CIC_IRS_Exclusive_Label']}</Text>
                                            <Paragraph fill>{site.localizedStrings['ExternalPages.CIC_IRS_Exclusive_Statement']}</Paragraph>
                                        </Box>
                                        <Box gap="small">
                                            <Text weight="bold">{site.localizedStrings['ExternalPages.CIC_IRS_Employee_Label']}</Text>
                                            <Paragraph fill>{site.localizedStrings['ExternalPages.CIC_IRS_Employee_Statement']}</Paragraph>
                                        </Box>
                                    </Box>}>
                                        <Button margin={{ left: "small" }}>
                                            <CircleInformation size="small" color="brand" style={{ marginBottom: "6px" }} />
                                        </Button>
                                    </Tip>
                                </Box>
                                <Box direction="row-responsive" gap="medium">
                                    {instructor?.isSalesCertified && <Box>
                                        <Text weight="bold">{salesLabel}</Text>
                                        <RadioButtonGroup name="salesStatus" id="salesStatus-input" options={statusOptions} border pad="xsmall"
                                            value={salesStatus} disabled={isEmployee} />
                                    </Box>}
                                    {instructor?.isTechnicalCertified && <Box>
                                        <Text weight="bold">{techLabel}</Text>
                                        <RadioButtonGroup name="technicalStatus" id="technicalStatus-input" options={statusOptions} border pad="xsmall"
                                            value={techinalStatus} disabled={isEmployee} />
                                    </Box>}
                                    {instructor?.isArubaCertified && <Box>
                                        <Text weight="bold">{arubaLabel}</Text>
                                        <RadioButtonGroup name="arubaStatus" id="arubaStatus-input" options={statusOptions} border pad="xsmall"
                                            value={arubaStatus} disabled={isEmployee} />
                                    </Box>}
                                </Box>
                            </Box>
                            <Box gap="small">
                                <Heading level={4}>{site.localizedStrings['ExternalPages.Certified_Instructor_Authorizations_Label']}</Heading>
                                <Box gap="small" width="large">
                                    {!hasAuthorizations && !site.loading && <Box fill="horizontal" pad={{ left: "small" }}>
                                        <Text>{site.localizedStrings['ExternalPages.Certified_Instructor_NoAuthorizations_Label']}</Text>
                                    </Box>}
                                    {hasAuthorizations && <DataTable fill pin="header" data={authorizations} columns={[
                                        {
                                            property: 'activityCode',
                                            header: site.localizedStrings["Datacard.Course_Id_Label"]
                                        },
                                        {
                                            property: 'activityName',
                                            header: site.localizedStrings['ExternalPages.Course_Title_Label']
                                        },
                                        {
                                            property: 'completionDate',
                                            header: site.localizedStrings['ExternalPages.Certification_Grant_Date_Label'],
                                            render: (datum: InstructorAuthorization) => <Text>
                                                {datum.completionDate
                                                    ? format(parseISO(datum.completionDate), 'yyyy-MMM-dd')
                                                    : ''}</Text>
                                        },
                                        {
                                            property: 'activityValidUntil',
                                            header: site.localizedStrings['ExternalPages.Certification_Valid_Until_Date_Label'],
                                            render: (datum: InstructorAuthorization) => <Text>
                                                {datum.activityValidUntil
                                                    ? format(parseISO(datum.activityValidUntil), 'yyyy-MMM-dd')
                                                    : ''}</Text>
                                        }
                                    ]} />
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Tab>}
                    <Tab title={String(site.localizedStrings['ExternalPages.Materials_Label'])} >
                        <Box width="large" pad={{ top: "medium", left: "small" }} gap="medium">
                            <Box direction="row-responsive" gap="small" width="medium">
                                <TextInput name="keywords" id="keywords-input" placeholder={`${site.localizedStrings['ExternalPages.Search_Label']}`}
                                    reverse type="search" icon={<Search id="search-icon" />} onChange={this.onKeywordsChange} />
                                <Button icon={<Filter />} onClick={this.onShowFilters} />
                            </Box>
                            <Box gap="small">
                                <Accordion multiple>
                                    {_.map(_.sortBy(technologies, t => t), technology => {
                                        let tech = technology.replace(" ", "_").toLowerCase();
                                        let techCourseware = technology === salesLabel
                                            ? salesCourseware
                                            : _.filter(technicalCourseware, ac => _.some(ac.course.technologies, act => act === technology));

                                        return <AccordionPanel key={`tech-pnl-${tech}`} label={technology}>
                                            <Accordion multiple>
                                                {_.map(techCourseware, cw => <Box gap="xxsmall" key={`courseware-${tech}`}>
                                                    {this.getMaterialPanel(cw, localUrl)}
                                                </Box>)}
                                            </Accordion>
                                        </AccordionPanel>;
                                    })}
                                </Accordion>
                            </Box>
                        </Box>
                    </Tab>
                    <Tab title={String(site.localizedStrings['Datacard.Training_Label'])} >
                        <Box gap="medium" width='large' pad={{ top: "medium" }}>
                            <Heading level={4}>{site.localizedStrings['ExternalPages.Instructor_Training_Events_Label']}</Heading>
                            <Paragraph fill>
                                <HtmlComponent html={site.localizedStrings['ExternalPages.Instructor_Training_Events_Statement']} />
                            </Paragraph>
                            <Box gap="small" pad="small">
                                {_.map(trainingEvents, trainingEvent => {
                                    return <li>
                                        <Anchor href={isEmployee ? trainingEvent.internalUrl : trainingEvent.externalUrl}
                                            label={trainingEvent.title} target="_blank" />
                                    </li>;
                                })}
                            </Box>
                        </Box>
                    </Tab>
                </Tabs>
                {this.state.addAffiliation && <Layer>
                    <Box gap="small" width="medium" pad="small">
                        <Header justify="center">
                            <Heading level={4}>{site.localizedStrings['ExternalPages.CIC_AddAffiliation_Label']}</Heading>
                        </Header>
                        <Box pad="small">
                            <AffiliationRequest onSubmit={this.onSaveAffiliation} onCancel={this.onCancelAddAffiliation} {...this.props} />
                        </Box>
                    </Box>
                </Layer>}
                {this.state.showFilters && <Layer animation="slide" onEsc={this.onHideFilters}>
                    <Box pad="small">
                        <Header>
                            <Heading level={4}>{site.localizedStrings['ExternalPages.Courseware_Filters_Label']}</Heading>
                            <Button icon={<FormClose />} onClick={this.onHideFilters} autoFocus />
                        </Header>
                        <Form value={this.state.advancedFilters} onChange={nextValue => this.setState({ advancedFilters: nextValue })}
                            onSubmit={this.onApplyFilters} onReset={this.onResetFilters} >
                            <Box direction="row-responsive" gap="medium">
                                <Box gap="small">
                                    <FormField label="Type" name="type">
                                        <Box direction="row-responsive" gap="small">
                                            <CheckBoxGroup options={filterTechnologies} name='type' gap="xxsmall" />
                                            {maxLength > splitLength &&
                                                <CheckBoxGroup options={filterTechnologies?.slice(splitLength, maxLength)} name='type' gap="xxsmall" />
                                            }
                                        </Box>
                                    </FormField>
                                    <Box direction='row-responsive' justify='between' gap="small">
                                        <Button secondary label="Deselect all" onClick={this.onDeselectTypes} />
                                        <Button type='reset' secondary label={`${site.localizedStrings['ExternalPages.ResetFilters_Label']}`} />
                                        <Button type='submit' primary label={`${site.localizedStrings['ExternalPages.ApplyFilters_Label']}`} />
                                    </Box>
                                </Box>
                                <Box gap="small">
                                    <FormField label={`${site.localizedStrings['ExternalPages.LastUpdatedRange_Label']}`} name='updateDate'>
                                        <DateInput name='updateDate' format="mm/dd/yyyy-mm/dd/yyyy" defaultValue={[]} calendarProps={{ size: "small" }} />
                                    </FormField>
                                </Box>
                            </Box>
                        </Form>
                    </Box>
                </Layer>}
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(CertificationInstructorConnection as any));
