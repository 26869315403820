import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, ResponsiveContext, Image, Heading, Text, Anchor, Tabs, Tab, Grid, Layer, Tip, Header, Button, Footer } from 'grommet';
import { LinkNext, CircleInformation, CaretLeftFill, CaretRightFill, FormClose } from 'grommet-icons';
import { formatString } from '../Utilities'
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import { retrievePortfolios, retrieveCertifications, retrieveCredentials } from '../store/Technical';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import { push } from 'connected-react-router';
import { firstBy } from 'thenby';

const mapDispatchToProps = {
    push,
    retrieveLocalizedStrings,
    retrievePortfolios,
    retrieveCertifications,
    retrieveCredentials,
    ...siteSlice.actions,
}

interface ITechnicalCertificationsProps {
}

interface TechnicalCertificationsState {
    activeTab: number;
    showBox: number;
    showCard: number;
    currentSection: number;
    certType: number;
    handlers: number[];
    showEmployeeWarning: boolean;
    handlerSet: boolean;
    autoplay: boolean;
}

type TechnicalCertificationsProps = ITechnicalCertificationsProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class TechnicalCertifications extends React.PureComponent<TechnicalCertificationsProps, TechnicalCertificationsState> {
    constructor(props: TechnicalCertificationsProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let tab = this.props.match
            ? this.props.match.params.activeTab
                ? this.props.match.params.activeTab
                : (this.props.location.state as any)?.activeTab
            : null;
        let langInfo = urlLanguage.split('-');
        let activeTab = 0;
        let keys = ['ExternalPages.Certification_And_Learning_Label', 'Metadata.TechnicalCertificationsKeywords,', 'Metadata.TechnicalCertificationsDescription',
            'ExternalPages.TechnicalCertifications_Intro_Statement', 'ExternalPages.TechnicalCertifications_Banner_Title',
            'ExternalPages.TechnicalCertifications_Banner_Statement', 'ExternalPages.TechnicalCertifications_Banner_Note',
            'ExternalPages.TechnicalCertifications_Tab2_Title', 'ExternalPages.TechnicalCertifications_Tab5_Section1_Title',
            'ExternalPages.TechnicalCertifications_Tab5_Section1_Link1', 'ExternalPages.TechnicalCertifications_Tab5_Section2_Statement',
            'ExternalPages.TechnicalCertifications_Tab5_Section2_Footnote', 'ExternalPages.TechnicalCertifications_Tab5_Section2_Title',
            'ExternalPages.TechnicalCertifications_Tab1_Section1_Title', 'ExternalPages.TechnicalCertifications_Tab1_Section1_Statement',
            'ExternalPages.TechnicalCertifications_Tab1_Section1_Link1', 'ExternalPages.TechnicalCertifications_Tab1_Section1_Link2',
            'ExternalPages.TechnicalCertifications_Tab1_Section2_Title', 'ExternalPages.TechnicalCertifications_Tab1_Section2_Statement',
            'ExternalPages.AllLevels_Label', 'ExternalPages.TechnicalCertifications_Min6Months_Statement',
            'ExternalPages.TechnicalCertifications_Min1Year_Statement', 'ExternalPages.TechnicalCertifications_Min3Year_Statement',
            'ExternalPages.TechnicalCertifications_Administrator_Tooltip', 'ExternalPages.Administrator_Label',
            'ExternalPages.TechnicalCertifications_Inegrator_Label', 'ExternalPages.TechnicalCertifications_Architect_Label',
            'ExternalPages.Overview_Section2_EarnCredential_Statement', 'ExternalPages.Credentials_Label',
            'ExternalPages.TechnicalCertifications_Inegrator_Tooltip', 'ExternalPages.TechnicalCertifications_Architect_Tooltip',
            'ExternalPages.TechnicalCertifications_Tab1_Card1_Info', 'ExternalPages.TechnicalCertifications_Tab1_Card2_Info_Title1',
            'ExternalPages.TechnicalCertifications_Tab1_Card1_Title', 'ExternalPages.TechnicalCertifications_Tab1_Card2_Title1',
            'ExternalPages.TechnicalCertifications_Tab1_Card2_Title2', 'ExternalPages.TechnicalCertifications_Tab1_Card3_Title1',
            'ExternalPages.TechnicalCertifications_Tab1_Card3_Title2', 'ExternalPages.TechnicalCertifications_Tab1_Card4_Title1',
            'ExternalPages.TechnicalCertifications_Tab1_Card4_Title2', 'ExternalPages.TechnicalCertifications_Tab1_Card3_Info_Title1',
            'ExternalPages.TechnicalCertifications_Tab1_Card4_Info_Title1', 'ExternalPages.TechnicalCertifications_Tab1_Card2_Info_Statement1',
            'ExternalPages.TechnicalCertifications_Tab1_Card2_Info_Statement2', 'ExternalPages.TechnicalCertifications_Tab1_Card3_Info_Statement1',
            'ExternalPages.TechnicalCertifications_Tab1_Card3_Info_Statement2', 'ExternalPages.TechnicalCertifications_Tab1_Card4_Info_Statement1',
            'ExternalPages.TechnicalCertifications_Tab1_Card4_Info_Statement2', 'ExternalPages.TechnicalCertifications_Tab2_Quote',
            'ExternalPages.TechnicalCertifications_Tab2_Card1_Statement', 'ExternalPages.TechnicalCertifications_Tab2_Card2_Statement',
            'ExternalPages.TechnicalCertifications_Tab2_Card3_Statement', 'ExternalPages.Certifications_Tab2_Card3_Link1',
            'ExternalPages.Certifications_Tab2_Card3_Link2', 'ExternalPages.TechnicalCertifications_Tab3_Title',
            'ExternalPages.TechnicalCertifications_Tab2_Card2_Link', 'ExternalPages.TechnicalCertifications_Tab3_Section_Title',
            'ExternalPages.TechnicalCertifications_Tab3_Section_Statement', 'ExternalPages.TechnicalCertifications_Tab3_Step1_Title',
            'ExternalPages.TechnicalCertifications_Tab3_Step2_Title', 'ExternalPages.TechnicalCertifications_Tab3_Step3_Title',
            'ExternalPages.TechnicalCertifications_Tab3_Step4_Title', 'ExternalPages.TechnicalCertifications_Tab3_Step1_Statement',
            'ExternalPages.TechnicalCertifications_Tab3_Note', 'ExternalPages.TechnicalCertifications_Tab3_Step2_Statement',
            'ExternalPages.TechnicalCertifications_Tab3_Step3_Statement', 'ExternalPages.TechnicalCertifications_Tab3_Step4_Statement',
            'ExternalPages.TechnicalCertifications_Tab4_Statement', 'ExternalPages.TechnicalCertifications_Tab4_Step2_Statement',
            'ExternalPages.TechnicalCertifications_Tab4_Step2', 'ExternalPages.TechnicalCertifications_Tab4_Step1',
            'ExternalPages.TechnicalCertifications_Tab4_Step3', 'ExternalPages.TechnicalCertifications_Tab4_Step4',
            'ExternalPages.TechnicalCertifications_Tab4_Step5', 'ExternalPages.TechnicalCertifications_Tab5_Section1_Statement',
            'ExternalPages.Certifications_ProgramOverview', 'ExternalPages.TechnicalCertifications_Tab2_Card3_Link3',
            'ExternalPages.TechnicalCertifications_Tab4_Title', 'ExternalPages.TechnicalCertifications_Tab5_Title',
            'ExternalPages.Overview_Section2', 'ExternalPages.ATP_Label', 'ExternalPages.TechnicalCertifications_Tab1_Card2_Info_Statement3',
            'ExternalPages.TechnicalCertifications_Tab1_Card3_Info_Statement3', 'ExternalPages.TechnicalCertifications_Tab1_Card4_Info_Statement3',
            'ExternalPages.Overview_Section2_EarnCredential_Box1_Link', 'ExternalPages.Access_Certifications_Label',
            'ExternalPages.Overview_Section4_Communities_Box1_Title', 'ExternalPages.Certifications_Tab2_Card2_Title',
            'ExternalPages.TechnicalCertifications_Tab2_Card1_Title', 'ExternalPages.Overview_Section2_EarnTechnical_Box4_Link',
            'ExternalPages.Certifications_Tab3_title', 'ExternalPages.Certifications_Tab3_Step1_Statement', 'ExternalPages.Solution_Certifications_Label',
            'ExternalPages.SalesCertifications_Tab3_Step4_Statement', 'ExternalPages.SalesCertifications_Tab3_Step3_Statement',
            'ExternalPages.SalesCertifications_Tab3_Step2_Statement', 'ExternalPages.Aruba_Certifications_Label', 'ExternalPages.Hpe_Certifications_Label',
            'ExternalPages.Career_Certifications_Label', 'ExternalPages.Product_Certifications_Label', 'ExternalPages.TechnicalCertifications_Tab2_Author',
            'General.LookupItem_Professional', 'General.LookupItem_Associate', 'ExternalPages.Specialist_Label', 'General.LookupItem_Expert',
            'Datacard.View_HPE_Certification_Label', 'ExternalPages.EmployeeCertification_Warning_Statement', 'ExternalPages.Yes_Label',
            'ExternalPages.No_Label', 'ExternalPages.TechnicalCertifications_Tab2_Intro_Statement', 'ExternalPages.MasterAseAdvantage_Label',
            'ExternalPages.TechnicalCertifications_Tab2_Card3_Title', 'ExternalPages.Brochure_Get_More_Label', 'ExternalPages.Your_Mission_Label',
            'ExternalPages.TechnicalCertifications_Tab4_Step3_Statement', 'ExternalPages.TechnicalCertifications_Tab4_Step4_Statement',
            'ExternalPages.TechnicalCertifications_Tab4_Step5_Statement', 'ExternalPages.TechnicalCertifications_EarnCredential_Statement',
            'ExternalPages.TechnicalCertifications_EarnCredential_Box1_Link', 'ExternalPages.MasterASE_Years', 'ExternalPages.MasterASE_Tooltip',
            'ExternalPages.ASE_Years', 'ExternalPages.ASE_Tooltip', 'ExternalPages.ATP_Years', 'ExternalPages.ATP_Tooltip',
            'ExternalPages.Solution_Certification_Statement', 'ExternalPages.Product_Certification_Statement', 'ExternalPages.Korean_Label',
            'ExternalPages.Aruba_Expert_Tooltip', 'ExternalPages.Aruba_Professional_Tooltip', 'ExternalPages.Aruba_Associate_Tooltip',
            'ExternalPages.English_Label', 'ExternalPages.Spanish_Label', 'ExternalPages.French_Label', 'ExternalPages.German_Label',
            'ExternalPages.Japanese_Label', 'ExternalPages.Portuguese_Label', 'ExternalPages.TechnicalCertifications_Tab5_Section1_Link2',
            'ExternalPages.TraditionalChinese_Label', 'ExternalPages.TechnicalCertifications_Tab5_Section1_Link3',
            'ExternalPages.TechnicalCertifications_InegratorArchitect_Label', 'ExternalPages.TechnicalCertifications_InegratorArchitect_Tooltip',
            'ExternalPages.Aruba_Product_Specialist_Label', 'ExternalPages.Technician_Label', 'ExternalPages.Aruba_Technician_Statement',
            'ExternalPages.Aruba_Advanced_Product_Certifications_Label'];

        switch (tab) {
            case 'tech-pro':
                activeTab = 1;
                break;
            case 'get-certified':
                activeTab = 2;
                break;
            case 'showcase':
                activeTab = 3;
                break;
            case 'keep-current':
                activeTab = 4;
                break;
        }
        if (tab == "video") {
            setTimeout(() => {
                let elem = document.getElementById("technical-video");

                elem?.scrollIntoView();
            }, 500);
        }
        this.state = {
            activeTab: activeTab,
            handlers: [],
            showBox: 0,
            showCard: 0,
            currentSection: 1,
            certType: 1,
            showEmployeeWarning: false,
            handlerSet: false,
            autoplay: false
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.setActiveMenu('technical-certification-menu');
        this.props.retrievePortfolios(urlLanguage);
        this.props.retrieveCertifications(urlLanguage);
        this.props.retrieveCredentials(urlLanguage);
        this.onActivateTab(activeTab);
    }

    public componentDidUpdate(previous: TechnicalCertificationsProps) {
        let site = this.props.site;
        let currentLang = site.currentLanguage;
        let previousLang = previous.site.currentLanguage;

        if (currentLang.value !== previousLang.value && !site.loading) {
            this.props.retrievePortfolios(currentLang.value);
            this.props.retrieveCertifications(currentLang.value);
            this.props.retrieveCredentials(currentLang.value);
        }
    }

    showBoxInfo = (box: number) => {
        this.setState({ showBox: box });
    }

    showCard = (card: number) => {
        this.setState({ showCard: card });
    }

    setCurrentSection = (section: number) => {
        this.setState({ currentSection: section });
    }

    showCertType = (type: number) => {
        this.setState({ certType: type });
    }

    onActivateTab = (activeTab: number) => {
        let handlers = [...this.state.handlers];

        this.setState({ activeTab: activeTab });
        switch (activeTab) {
            case 1:
                this.props.push("/technical-certifications/tech-pro");
                break;
            case 2:
                this.props.push("/technical-certifications/get-certified");
                break;
            case 3:
                this.props.push("/technical-certifications/showcase");
                break;
            case 4:
                this.props.push("/technical-certifications/keep-current");
                break;
            default:
                {
                    this.props.push("/technical-certifications/");
                    break;
                }
        }
        if (activeTab == 1 && _.indexOf(handlers, activeTab) == -1) {
            setTimeout(() => {
                handlers.push(activeTab);
                this.setState({ handlers: handlers });
            }, 1000);
        }
        if (activeTab == 2 && _.indexOf(handlers, activeTab) === -1) {
            setTimeout(() => {
                let employeeUrl = `${process.env.REACT_APP_ADMIN_SITE}/employeeCertifications`
                let links = jQuery(`a[href*='${employeeUrl}']`);

                if (links.length !== 0 && !this.state.handlers.includes(activeTab)) {
                    links.on('click', () => { this.setState({ showEmployeeWarning: true }) });
                    links.attr('href', 'javascript:void(0)');
                    handlers.push(activeTab);
                }
                this.setState({ handlers: handlers });
            }, 1000);
        }
        if (activeTab == 3 && _.indexOf(handlers, activeTab) === -1) {
            setTimeout(() => {
                handlers.push(activeTab);
                this.setState({ handlers: handlers });
            }, 1000);
        }
    }

    gotoSales = () => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;

        window.location.href = `${localUrl}/sales-certifications`;
    }

    renderAruba = (level: number) => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let arubaCerts = this.props.technical.certifications?.filter(cert => cert.isAruba && cert.levelOrder === level);
        let architectCerts = arubaCerts?.filter((cert) => (/.*ACNA.*/).test(cert.programId));
        let dataCenterCerts = arubaCerts?.filter((cert) => !(/.*ACNA.*/).test(cert.programId) && (/.*-DC/).test(cert.programId));
        let campusAccessCerts = arubaCerts?.filter((cert) => !(/.*ACNA.*/).test(cert.programId) &&
            ((/.*-CamAcss/).test(cert.programId) || (/.*-CA/).test(cert.programId)));
        let switchingCerts = arubaCerts?.filter((cert) => (/.*ACS.*/).test(cert.programId));
        let networkSecurityCerts = arubaCerts?.filter((cert) => (/.*ACNS.*/).test(cert.programId) || (/.*AC.*NS/).test(cert.programId));
        let row = [];

        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(architectCerts, cert => <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`architect-${level}`} src={cert.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);
        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(campusAccessCerts, cert => <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`campus-access-${level}`} src={cert.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);
        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(dataCenterCerts, cert => <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`data-center-${level}`} src={cert.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);
        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(networkSecurityCerts, cert => <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`network-security-${level}`} src={cert.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);
        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(switchingCerts, cert => <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`switching-${level}`} src={cert.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);

        return row;
    }

    renderArubaCredentials = (level: number) => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let credentials = this.props.technical.credentials?.filter(cred => cred.levelOrder === level);
        let centralCreds = credentials?.filter((cred) => (/.*Central.*/).test(cred.name));
        let cx10000Creds = credentials?.filter((cred) => (/.*CX 10000.*/).test(cred.name));
        let otherCreds = credentials?.filter((cred) => !(/.*Central.*/).test(cred.name) && !(/.*CX 10000.*/).test(cred.name));
        let row = [];

        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(centralCreds, cred => <Anchor href={`${localUrl}/datacard//${cred.entityType}/${cred.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`central-${level}`} src={cred.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);
        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(cx10000Creds, cred => <Anchor href={`${localUrl}/datacard//${cred.entityType}/${cred.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`cx10000-${level}`} src={cred.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);
        row.push(<Box gap="small" direction="row-responsive">
            {_.map(otherCreds, cred => <Anchor href={`${localUrl}/datacard//${cred.entityType}/${cred.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`other-${level}`} src={cred.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);

        return row;
    }

    renderArubaAdvanced = (level: number) => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let certifications = this.props.technical.certifications?.filter(cert => cert.levelOrder === level);
        let centralCerts = certifications?.filter((cert) => (/.*Central.*/).test(cert.programId));
        let cx10000Certs = certifications?.filter((cert) => (/.*CX10000.*/).test(cert.programId));
        let otherCerts = certifications?.filter((cert) => !(/.*Central.*/).test(cert.programId) && !(/.*CX 10000.*/).test(cert.programId));
        let row = [];

        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(centralCerts, cert => <Anchor href={`${localUrl}/datacard//certification/${cert.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`central-cert-${level}`} src={cert.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);
        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(cx10000Certs, cert => <Anchor href={`${localUrl}/datacard//certification/${cert.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`cx10000-cert-${level}`} src={cert.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);
        row.push(<Box gap="small" width={{ min: "small" }} height={{ min: "small" }}>
            {_.map(otherCerts, cert => <Anchor href={`${localUrl}/datacard//certification/${cert.programId}`}>
                <Box width="small" height="small"><Image fit="contain" key={`other-cert-${level}`} src={cert.digitalBadge} /></Box>
            </Anchor>)}
        </Box>);

        return row;
    }

    openEmployeeSite = () => {
        let employeeUrl = `${process.env.REACT_APP_ADMIN_SITE}/EmployeeCertifications`;

        this.setState({ showEmployeeWarning: false });
        window.open(employeeUrl, "_blank");
    }

    public render() {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let adminServerUrl = `${process.env.REACT_APP_ADMIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: `${localUrl}/` },
            { key: site.menuStrings['ExternalPages.Navigation_Technical'], value: "" }
        ];
        let title = site.menuStrings['ExternalPages.Navigation_Technical'];
        let portfolios = this.props.technical.portfolios ?? [];
        let architects = _.filter(portfolios, portfolio => portfolio.role == 'Architect');
        let integrators = _.filter(portfolios, portfolio => portfolio.role == 'Integrator');
        let administrators = _.filter(portfolios, portfolio => portfolio.role == 'Administrator');
        let certifications = this.props.technical.certifications ?? [];
        let arubaCerts = this.props.technical.certifications?.filter(cert => cert.isAruba);
        let hasAdvancedAruba = _.some(arubaCerts, cert => cert.levelOrder === 13);
        let hasProductSpecialist = this.props.technical.credentials && this.props.technical.credentials.length != 0;

        return <ResponsiveContext.Consumer>
            {size => (
                <BasePage breadcrumbs={breadcrumbs} title={title} pageName="TechnicalCertifications" {...this.props}
                    metadataKeywords={site.localizedStrings['Metadata.TechnicalCertificationsKeywords']}
                    metadataDescription={site.localizedStrings['Metadata.TechnicalCertificationsDescription']} >
                    {this.state.showEmployeeWarning && <Layer>
                        <Box width={{ max: "large" }} gap="small">
                            <Header background="brand" round={{ "size": "small", "corner": "top" }} fill>
                                <Box fill="horizontal">
                                    <Text textAlign="center" color="white">{site.localizedStrings['Datacard.View_HPE_Certification_Label']}</Text>
                                </Box>
                            </Header>
                            <Box pad="small">
                                <Paragraph fill textAlign="center">
                                    {parse(site.localizedStrings['ExternalPages.EmployeeCertification_Warning_Statement'])}
                                </Paragraph>
                            </Box>
                            <Footer fill="horizontal" pad="small" justify="end">
                                <Box direction="row-responsive" gap="small" alignSelf="end">
                                    <Button primary label={site.localizedStrings['ExternalPages.Yes_Label']} onClick={this.openEmployeeSite} />
                                    <Button secondary label={site.localizedStrings['ExternalPages.No_Label']}
                                        onClick={() => { this.setState({ showEmployeeWarning: false }) }} />
                                </Box>
                            </Footer>
                        </Box>
                    </Layer>}
                    {site.stringsLoaded && <Box gap="medium">
                        <Heading textAlign="center" fill >{(title ?? "")}</Heading>
                        <Tabs activeIndex={this.state.activeTab} onActive={this.onActivateTab} justify="center">
                            <Tab title={site.localizedStrings['ExternalPages.Certifications_ProgramOverview']}>
                                <Box fill gap="medium" pad={{ top: "large" }}>
                                    <Box direction="row-responsive" gap="large" pad="small">
                                        <Box width={{ max: 'large' }} gap="small" alignSelf="center">
                                            <Heading level="4">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab1_Section1_Title']}</Heading>
                                            <Paragraph fill size="large">
                                                {parse(formatString(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab1_Section1_Statement'],
                                                    [`${localUrl}/credentials`]))}
                                            </Paragraph>
                                            <Anchor color="brand" size="medium" href='https://psnow.ext.hpe.com/doc/a00098671enw' target='_blank'
                                                label={<Text>{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab1_Section1_Link1']} <LinkNext color="brand" size="small" /></Text>} />
                                            <Anchor color="brand" size="medium" href={`${localUrl}/documents/external/4AA4-2093.pdf`}
                                                label={<Text>{site.localizedStrings['ExternalPages.Brochure_Get_More_Label']} <LinkNext color="brand" size="small" /></Text>} />
                                        </Box>
                                        <Box id="technical-video" width="large" height="medium" alignSelf="center" >
                                            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/uMKlJGUIe60`}
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen title="HPE Technical Certifications" />
                                        </Box>
                                    </Box>
                                    <Box fill align="center" gap="small">
                                        <Heading level="4" fill textAlign="center">
                                            {site.localizedStrings['ExternalPages.TechnicalCertifications_Tab1_Section2_Title']}
                                        </Heading>
                                        <Paragraph fill textAlign="center">
                                            {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab1_Section2_Statement'])}
                                        </Paragraph>
                                    </Box>
                                    <Tabs justify="start">
                                        <Tab title={site.localizedStrings['ExternalPages.Hpe_Certifications_Label']}>
                                            <Box gap="small" pad={{ top: "small", bottom: "small" }} >
                                                <Box direction="row-responsive" gap="small" fill>
                                                    <Box width="medium" justify="center" gap="small">
                                                        <Heading level="4" fill>
                                                            {site.localizedStrings['ExternalPages.Your_Mission_Label']}
                                                        </Heading>
                                                    </Box>
                                                    <Box width="medium" gap="small" border="right" >
                                                        <Heading level="4" textAlign="center" fill>
                                                            {site.localizedStrings['ExternalPages.TechnicalCertifications_Architect_Label']}
                                                        </Heading>
                                                        <Text textAlign="center">
                                                            {site.localizedStrings['ExternalPages.TechnicalCertifications_Architect_Tooltip']}
                                                        </Text>
                                                    </Box>
                                                    <Box width="medium" gap="small" border="right" >
                                                        <Heading level="4" textAlign="center" fill>
                                                            {site.localizedStrings['ExternalPages.TechnicalCertifications_InegratorArchitect_Label']}
                                                        </Heading>
                                                        <Text textAlign="center">
                                                            {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_InegratorArchitect_Tooltip'])}
                                                        </Text>
                                                    </Box>
                                                    <Box width="medium" gap="small" >
                                                        <Heading level="4" textAlign="center" fill>
                                                            {site.localizedStrings['ExternalPages.Administrator_Label']}
                                                        </Heading>
                                                        <Text textAlign="center">
                                                            {site.localizedStrings['ExternalPages.TechnicalCertifications_Administrator_Tooltip']}
                                                        </Text>
                                                    </Box>
                                                </Box>
                                                <Box background="#0E5265" align="center" justify="center">
                                                    <Heading level="4" textAlign="center" fill>
                                                        {site.localizedStrings['ExternalPages.Career_Certifications_Label']}
                                                    </Heading>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" fill>
                                                    <Box width="medium" />
                                                    <Box direction="row-responsive" width="medium" gap="small" border="right" align="center" justify="center">
                                                        {
                                                            _.map(architects, portfolio => {
                                                                return <Anchor label={portfolio.name}
                                                                    href={`${localUrl}/certifications?technologies=${encodeURIComponent(portfolio.value)}`} />
                                                            })
                                                        }
                                                    </Box>
                                                    <Box direction="row-responsive" width="medium" gap="small" border="right" align="center" justify="center">
                                                        {
                                                            _.map(integrators, portfolio => {
                                                                return <Anchor label={portfolio.name}
                                                                    href={`${localUrl}/certifications?technologies=${encodeURIComponent(portfolio.value)}`} />
                                                            })
                                                        }
                                                    </Box>
                                                    <Box direction="row-responsive" width="medium" gap="small" align="center" justify="center">
                                                        {
                                                            _.map(administrators, portfolio => {
                                                                return <Anchor label={portfolio.name}
                                                                    href={`${localUrl}/certifications?technologies=${encodeURIComponent(portfolio.value)}`} />
                                                            })
                                                        }
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" >
                                                    <Box width="medium" justify="center" gap="small">
                                                        <Box direction="row-responsive" gap="small">
                                                            <Heading level="4" fill>HPE Master Accredited Solutions Expert (HPE Master ASE)</Heading>
                                                            <Tip content={<Box width="medium">
                                                                {parse(site.localizedStrings['ExternalPages.MasterASE_Tooltip'])}
                                                            </Box>}>
                                                                <CircleInformation />
                                                            </Tip>
                                                        </Box>
                                                        <Text>{site.localizedStrings['ExternalPages.MasterASE_Years']}</Text>
                                                    </Box>
                                                    <Box width="medium" gap="small" border="right">
                                                        <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                            {_.filter(certifications, cert => !cert.isAruba && cert.levelOrder === 1 &&
                                                                _.some(architects, architect => _.some(cert.technologies, tech => tech == architect.id)) &&
                                                                cert.level.indexOf("Product") === -1).sort(firstBy("name")).map((cert, ndx) =>
                                                                    <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                        <Box width="small" height="small">
                                                                            <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`}
                                                                                src={cert.digitalBadge} />
                                                                        </Box>
                                                                    </Anchor>)}
                                                        </Grid>
                                                    </Box>
                                                    <Box width="medium" gap="small" border="right">
                                                        <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                            {_.filter(certifications, cert => !cert.isAruba && cert.levelOrder === 1 &&
                                                                _.some(integrators, integrator => _.some(cert.technologies, tech => tech == integrator.id)) &&
                                                                cert.level.indexOf("Product") === -1).sort(firstBy("name")).map((cert, ndx) =>
                                                                    <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                        <Box width="small" height="small">
                                                                            <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`}
                                                                                src={cert.digitalBadge} />
                                                                        </Box>
                                                                    </Anchor>)}
                                                        </Grid>
                                                    </Box>
                                                    <Box width="medium" gap="small" >
                                                        <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                            {_.filter(certifications, cert => !cert.isAruba && cert.levelOrder === 1 &&
                                                                _.some(administrators, admin => _.some(cert.technologies, tech => tech == admin.id)) &&
                                                                cert.level.indexOf("Product") === -1).sort(firstBy("name")).map((cert, ndx) =>
                                                                    <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                        <Box width="small" height="small">
                                                                            <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`}
                                                                                src={cert.digitalBadge} />
                                                                        </Box>
                                                                    </Anchor>)}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" background="#F5F5F5">
                                                    <Box width="medium" justify="center" gap="small">
                                                        <Box direction="row-responsive" gap="small">
                                                            <Heading level="4" fill>HPE Accredited Solutions Expert (HPE ASE)</Heading>
                                                            <Tip content={<Box width="medium">
                                                                {parse(site.localizedStrings['ExternalPages.ASE_Tooltip'])}
                                                            </Box>}>
                                                                <CircleInformation />
                                                            </Tip>
                                                        </Box>
                                                        <Text>{site.localizedStrings['ExternalPages.ASE_Years']}</Text>
                                                    </Box>
                                                    <Box width="medium" gap="small" border="right">
                                                        <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                            {_.filter(certifications, cert => !cert.isAruba && cert.levelOrder === 2 &&
                                                                _.some(architects, architect => _.some(cert.technologies, tech => tech == architect.id)) &&
                                                                cert.level.indexOf("Product") === -1).sort(firstBy("name")).map((cert, ndx) =>
                                                                    <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                        <Box width="small" height="small">
                                                                            <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`}
                                                                                src={cert.digitalBadge} />
                                                                        </Box>
                                                                    </Anchor>)}
                                                        </Grid>
                                                    </Box>
                                                    <Box width="medium" gap="small" border="right">
                                                        <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                            {_.filter(certifications, cert => !cert.isAruba && cert.levelOrder === 2 &&
                                                                _.some(integrators, integrator => _.some(cert.technologies, tech => tech == integrator.id)) &&
                                                                cert.level.indexOf("Product") === -1).sort(firstBy("name")).map((cert, ndx) =>
                                                                    <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                        <Box width="small" height="small">
                                                                            <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`}
                                                                                src={cert.digitalBadge} />
                                                                        </Box>
                                                                    </Anchor>)}
                                                        </Grid>
                                                    </Box>
                                                    <Box width="medium" gap="small" >
                                                        <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                            {_.filter(certifications, cert => !cert.isAruba && cert.levelOrder === 2 &&
                                                                _.some(administrators, admin => _.some(cert.technologies, tech => tech == admin.id)) &&
                                                                cert.level.indexOf("Product") === -1).sort(firstBy("name")).map((cert, ndx) =>
                                                                    <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                        <Box width="small" height="small">
                                                                            <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`}
                                                                                src={cert.digitalBadge} />
                                                                        </Box>
                                                                    </Anchor>)}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" >
                                                    <Box width="medium" justify="center" gap="small">
                                                        <Box direction="row-responsive" gap="small">
                                                            <Heading level="4" fill>HPE Accredited Technical Professional (HPE ATP)</Heading>
                                                            <Tip content={<Box width="medium">
                                                                {parse(site.localizedStrings['ExternalPages.ATP_Tooltip'])}
                                                            </Box>}>
                                                                <CircleInformation />
                                                            </Tip>
                                                        </Box>
                                                        <Text>{site.localizedStrings['ExternalPages.ATP_Years']}</Text>
                                                    </Box>
                                                    <Box width="medium" gap="small" border="right">
                                                        <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                            {_.filter(certifications, cert => !cert.isAruba && cert.levelOrder === 3 &&
                                                                _.some(architects, architect => _.some(cert.technologies, tech => tech == architect.id)) &&
                                                                cert.level.indexOf("Product") === -1).sort(firstBy("name")).map((cert, ndx) =>
                                                                    <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                        <Box width="small" height="small">
                                                                            <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`}
                                                                                src={cert.digitalBadge} />
                                                                        </Box>
                                                                    </Anchor>)}
                                                        </Grid>
                                                    </Box>
                                                    <Box width="medium" gap="small" border="right">
                                                        <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                            {_.filter(certifications, cert => !cert.isAruba && cert.levelOrder === 3 &&
                                                                _.some(integrators, integrator => _.some(cert.technologies, tech => tech == integrator.id)) &&
                                                                cert.level.indexOf("Product") === -1).sort(firstBy("name")).map((cert, ndx) =>
                                                                    <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                        <Box width="small" height="small">
                                                                            <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`}
                                                                                src={cert.digitalBadge} />
                                                                        </Box>
                                                                    </Anchor>)}
                                                        </Grid>
                                                    </Box>
                                                    <Box width="medium" gap="small" >
                                                        <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                            {_.filter(certifications, cert => !cert.isAruba && cert.levelOrder === 3 &&
                                                                _.some(administrators, admin => _.some(cert.technologies, tech => tech == admin.id)) &&
                                                                cert.level.indexOf("Product") === -1).sort(firstBy("name")).map((cert, ndx) =>
                                                                    <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                        <Box width="small" height="small">
                                                                            <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`}
                                                                                src={cert.digitalBadge} />
                                                                        </Box>
                                                                    </Anchor>)}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box background="#0E5265">
                                                    <Heading level="4" textAlign="center" fill>
                                                        {site.localizedStrings['ExternalPages.Solution_Certifications_Label']}
                                                    </Heading>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" alignContent="center" align="center" justify="center" fill>
                                                    <Box width="medium">
                                                        <Paragraph>{site.localizedStrings['ExternalPages.Solution_Certification_Statement']}</Paragraph>
                                                    </Box>
                                                    <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                        {certifications?.filter(cert => !cert.isAruba && cert.level.indexOf("Solution") !== -1)
                                                            .sort(firstBy("name")).map((cert, ndx) =>
                                                                <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                    <Box width="small" height="small">
                                                                        <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`} src={cert.digitalBadge} />
                                                                    </Box>
                                                                </Anchor>)}
                                                    </Grid>
                                                </Box>
                                                <Box background="#0E5265">
                                                    <Heading level="4" textAlign="center" fill>
                                                        {site.localizedStrings['ExternalPages.Product_Certifications_Label']}
                                                    </Heading>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" alignContent="center" align="center" justify="center" fill>
                                                    <Box width="medium">
                                                        <Paragraph>{site.localizedStrings['ExternalPages.Product_Certification_Statement']}</Paragraph>
                                                    </Box>
                                                    <Grid rows="auto" columns="small" gap="small" fill align="center" justify="center">
                                                        {certifications?.filter(cert => !cert.isAruba && cert.level.indexOf("Product") !== -1)
                                                            .sort(firstBy("name")).map((cert, ndx) =>
                                                                <Anchor href={`${localUrl}/datacard/certification/${cert.programId}`}>
                                                                    <Box width="small" height="small">
                                                                        <Image fit="contain" key={`cert-lvl${cert.levelOrder}-${ndx}`} src={cert.digitalBadge} />
                                                                    </Box>
                                                                </Anchor>)}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Tab>
                                        <Tab title={site.localizedStrings['ExternalPages.Aruba_Certifications_Label']}>
                                            <Box pad={{ top: "small", bottom: "small" }}>
                                                <Box>
                                                    <Box background="#8AC5E2">
                                                        <Heading level="4" textAlign="center" fill>
                                                            {site.localizedStrings['ExternalPages.Career_Certifications_Label']}
                                                        </Heading>
                                                    </Box>
                                                    <Box direction="row" gap="medium" align="center" pad="small" background="#ADE1EF">
                                                        <Box width="medium" />
                                                        <Grid rows="auto" columns="small" gap="medium" fill pad={{ left: "small" }} align="center" justify="center">
                                                            <Text weight="bold">Architect</Text>
                                                            <Text weight="bold">Campus Access</Text>
                                                            <Text weight="bold">Data Center</Text>
                                                            <Text weight="bold">Network Security</Text>
                                                            <Text weight="bold">Switching</Text>
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="medium" align="center" pad="small"
                                                    border={{ side: "all", color: "#7FF9E2", size: "small" }}>
                                                    <Box width="medium" gap="small">
                                                        <Box direction="row-responsive" gap="small">
                                                            <Heading level="4" fill>{site.localizedStrings['General.LookupItem_Expert']}</Heading>
                                                            <Tip content={<Box width="medium">
                                                                {parse(site.localizedStrings['ExternalPages.Aruba_Expert_Tooltip'])}
                                                            </Box>}>
                                                                <CircleInformation />
                                                            </Tip>
                                                        </Box>
                                                        <Text>{site.localizedStrings['ExternalPages.MasterASE_Years']}</Text>
                                                    </Box>
                                                    <Box direction="row-responsive" gap="small" alignContent="center" align="center" justify="center" fill pad="small"
                                                        border={{ side: "left", color: "#7FF9E2", size: "small" }}>
                                                        <Grid rows="auto" columns="small" gap="medium" fill pad={{ left: "small" }} align="center" justify="center">
                                                            {this.renderAruba(1)}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="medium" align="center" pad="small"
                                                    border={{ side: "all", color: "#7FF9E2", size: "small" }}>
                                                    <Box width="medium" gap="small">
                                                        <Box direction="row-responsive" gap="small">
                                                            <Heading level="4" fill>{site.localizedStrings['General.LookupItem_Professional']}</Heading>
                                                            <Tip content={<Box width="medium">
                                                                {parse(site.localizedStrings['ExternalPages.Aruba_Professional_Tooltip'])}
                                                            </Box>}>
                                                                <CircleInformation />
                                                            </Tip>
                                                        </Box>
                                                        <Text>{site.localizedStrings['ExternalPages.ASE_Years']}</Text>
                                                    </Box>
                                                    <Box direction="row-responsive" gap="small" alignContent="center" align="center" justify="center" fill pad="small"
                                                        border={{ side: "left", color: "#7FF9E2", size: "small" }}>
                                                        <Grid rows="auto" columns="small" gap="medium" fill pad={{ left: "small" }} align="center" justify="center">
                                                            {this.renderAruba(2)}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="medium" align="center" pad="small"
                                                    border={{ side: "all", color: "#7FF9E2", size: "small" }}>
                                                    <Box width="medium" gap="small" >
                                                        <Box direction="row-responsive" gap="small">
                                                            <Heading level="4" fill>{site.localizedStrings['General.LookupItem_Associate']}</Heading>
                                                            <Tip content={<Box width="medium">
                                                                {parse(site.localizedStrings['ExternalPages.Aruba_Associate_Tooltip'])}
                                                            </Box>}>
                                                                <CircleInformation />
                                                            </Tip>
                                                        </Box>
                                                        <Text>{site.localizedStrings['ExternalPages.ATP_Years']}</Text>
                                                    </Box>
                                                    <Box direction="row-responsive" gap="small" alignContent="center" align="center" justify="center" fill pad="small"
                                                        border={{ side: "left", color: "#7FF9E2", size: "small" }}>
                                                        <Grid rows="auto" columns="small" gap="medium" fill pad={{ left: "small" }} align="center" justify="center">
                                                            {this.renderAruba(3)}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="medium" align="center" pad="small"
                                                    border={{ side: "all", color: "#7FF9E2", size: "small" }}>
                                                    <Box width="medium" gap="small" >
                                                        <Heading level="4" fill>{site.localizedStrings['ExternalPages.Technician_Label']}</Heading>
                                                        <Text>{site.localizedStrings['ExternalPages.Aruba_Technician_Statement']}</Text>
                                                    </Box>
                                                    <Box direction="row-responsive" gap="small" alignContent="center" align="center" justify="center" fill pad="small"
                                                        border={{ side: "left", color: "#7FF9E2", size: "small" }} >
                                                        <Grid rows="auto" columns="small" gap="medium" fill pad={{ left: "small" }} align="center" justify="center">
                                                            {this.renderAruba(4)}
                                                        </Grid>
                                                    </Box>
                                                </Box>
                                                {hasAdvancedAruba && <Box>
                                                    <Box background="#8AC5E2">
                                                        <Heading level="4" textAlign="center" fill>
                                                            {site.localizedStrings['ExternalPages.Aruba_Advanced_Product_Certifications_Label']}
                                                        </Heading>
                                                    </Box>
                                                    <Box direction="row" gap="medium" alignContent="center" align="center" pad="small" background="#ADE1EF">
                                                        <Grid rows="auto" columns="small" gap="medium" fill align="center" justify="center">
                                                            <Text weight="bold">Central</Text>
                                                            <Text weight="bold">CX 10000</Text>
                                                            <Text weight="bold">SSE and EdgeConnect</Text>
                                                        </Grid>
                                                    </Box>
                                                    <Box direction="row-responsive" gap="small" alignContent="center" align="center" justify="center" fill>
                                                        <Grid rows="auto" columns="small" gap="medium" fill align="center" justify="center">
                                                            {this.renderArubaAdvanced(13)}
                                                        </Grid>
                                                    </Box>
                                                </Box>}
                                            </Box>
                                        </Tab>
                                        <Tab title={site.localizedStrings['ExternalPages.Credentials_Label']}>
                                            <Box pad={{ top: "small", bottom: "small" }} alignContent="center">
                                                <Box background="#8AC5E2">
                                                    <Heading level="4" textAlign="center" fill>
                                                        {site.localizedStrings['ExternalPages.Aruba_Product_Specialist_Label']}
                                                    </Heading>
                                                </Box>
                                                <Box direction="row" gap="medium" alignContent="center" align="center" pad="small" background="#ADE1EF">
                                                    <Grid rows="auto" columns="small" gap="medium" fill align="center" justify="center">
                                                        <Text weight="bold">Central</Text>
                                                        <Text weight="bold">CX 10000</Text>
                                                        <Text weight="bold">SSE and EdgeConnect</Text>
                                                    </Grid>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" alignContent="center" align="center" justify="center" fill
                                                    pad={{ top: "small" }}>
                                                    <Grid rows="auto" columns="small" gap="medium" fill align="center" justify="center">
                                                        {this.renderArubaCredentials(2)}
                                                    </Grid>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" alignContent="center" align="center" justify="center" fill
                                                    pad={{ top: "small" }}>
                                                    <Grid rows="auto" columns="small" gap="medium" fill align="center" justify="center">
                                                        {this.renderArubaCredentials(3)}
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        </Tab>
                                    </Tabs>
                                </Box>
                            </Tab>
                            <Tab title={site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Title']}>
                                <Box fill gap="medium" pad="large" >
                                    <Paragraph fill size="large">
                                        {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Intro_Statement'])}
                                    </Paragraph>
                                    <Box pad="medium" border={{ side: 'all', color: 'brand', size: "medium" }} gap="small" justify="center" >
                                        <Paragraph fill size="xlarge">
                                            {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Quote'])}
                                        </Paragraph>
                                        <Paragraph fill>{parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Author'])}</Paragraph>
                                    </Box>
                                    <Box background="#F5F5F5" direction="row-responsive" gap="medium" pad="medium" justify="center" fill>
                                        <Box gap="small" background="white" width="medium">
                                            <Image src="images/technical-certifications/HPE20160726036_800_0_72_RGB.jpg" />
                                            <Box pad="small" background="white">
                                                <Heading level="4">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Card1_Title']}</Heading>
                                                <Paragraph>{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Card1_Statement']}</Paragraph>
                                                <Anchor href="https://www.mylearninghpe.com/hpcpbenefits/LearningPaths.aspx"
                                                    target="_blank" label={`${site.localizedStrings['ExternalPages.Certifications_Tab2_Card3_Link2']}`} />
                                                <Anchor href="https://www.hpe.com/h22228/video-gallery/us/en/v100001145/why-join-hpe-tech-pro/video/"
                                                    target="_blank" label={`${site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Card3_Link3']}`} />
                                            </Box>
                                        </Box>
                                        <Box gap="small" background="white" width="medium">
                                            <Image src="images/technical-certifications/HPE20160824078_1600_0_72_RGB.jpg" />
                                            <Box pad="small">
                                                <Heading level="4">{site.localizedStrings['ExternalPages.MasterAseAdvantage_Label']}</Heading>
                                                <Paragraph>
                                                    {parse(formatString(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Card2_Statement'],
                                                        [`${localUrl}/master-ase-advantage`]))}
                                                </Paragraph>
                                            </Box>
                                        </Box>
                                        <Box gap="small" background="white" width="medium">
                                            <Image src="images/technical-certifications/HPE20170126007_1600_0_72_RGB.jpg" />
                                            <Box pad="small">
                                                <Heading level="4">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Card3_Title']}</Heading>
                                                <Paragraph>{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab2_Card3_Statement']}</Paragraph>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Tab>
                            <Tab title={site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Title']}>
                                <Box pad={{ top: "large", bottom: "small" }} fill>
                                    <Box gap="medium" >
                                        <Box direction="row-responsive" gap="large" fill>
                                            <Box gap="small" alignSelf="center">
                                                <Heading level="3">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Section_Title']}</Heading>
                                                <Paragraph fill>
                                                    {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Section_Statement'])}
                                                </Paragraph>
                                            </Box>
                                            <Box fill alignSelf="center">
                                                <Image fit="contain" src="images/technical-certifications/GettyImages-534865239.png" />
                                            </Box>
                                        </Box>
                                        <Box direction="row-responsive" gap="small" pad="medium">
                                            <Box gap="small" width="medium" >
                                                <Image src="images/technical-certifications/GettyImages-463410037_450_0_72_RGB.jpg" />
                                                <Box pad="small" >
                                                    <Text size="large" weight="bold">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Step1_Title']}</Text>
                                                    <Box>{parse(formatString(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Step1_Statement'],
                                                        [`${localUrl}/getstarted`, `${localUrl}/technical-certifications`,
                                                            'https://hpe.seismic.com/Link/Content/DCRgQfRcqQcqTGQTj3VD3TG2849B',
                                                        `${localUrl}/documents/CertificationPath/CertificationPaths_A4.pdf`,
                                                        `${localUrl}/documents/CertificationPath/CertificationPaths_Letter.pdf`,
                                                        `${localUrl}/certifications`, `${adminServerUrl}/employeeCertifications`]))}</Box>
                                                </Box>
                                            </Box>
                                            <Box gap="small" width="medium" >
                                                <Image src="images/technical-certifications/AdobeStock_417101150_450_0_72_RGB.jpg" />
                                                <Box pad="small" >
                                                    <Text size="large" weight="bold">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Step2_Title']}</Text>
                                                    <Box>{parse(formatString(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Step2_Statement'],
                                                        [`${localUrl}/courses?formats=ilt`,
                                                        `${localUrl}/trainingCalendar?formats=ilt`,
                                                        `${localUrl}/courses?formats=wbt`,
                                                        `${localUrl}/trainingCalendar?formats=web based`,
                                                        `${localUrl}/hpepress`]))}</Box>
                                                </Box>
                                            </Box>
                                            <Box gap="small" width="medium" >
                                                <Image src="images/technical-certifications/GettyImages-682628312_450_0_72_RGB.jpg" />
                                                <Box pad="small" >
                                                    <Text size="large" weight="bold">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Step3_Title']}</Text>
                                                    <Box>{parse(formatString(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Step3_Statement'], [
                                                        `${localUrl}/certification-exams`,
                                                        `${localUrl}/certification-exams/preparation`,
                                                        `${localUrl}/certification-exams/vouchers`,
                                                        `${localUrl}/certification-exams/types`]))}</Box>
                                                </Box>
                                            </Box>
                                            <Box gap="small" width="medium" >
                                                <Image src="images/technical-certifications/AdobeStock_417101524_450_0_72_RGB.jpg" />
                                                <Box pad="small" >
                                                    <Text size="large" weight="bold">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Step4_Title']}</Text>
                                                    <Box>{parse(formatString(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Step4_Statement'], [
                                                        `${localUrl}/technical-certifications-badges`,
                                                        `${localUrl}/keep-current`,
                                                    ]))}</Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box pad="medium">
                                            <Paragraph size="small" fill>
                                                {site.localizedStrings['ExternalPages.TechnicalCertifications_Tab3_Note']}
                                            </Paragraph>
                                        </Box>
                                    </Box>
                                </Box>
                            </Tab>
                            <Tab title={site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Title']}>
                                <Box pad={{ top: "large", bottom: "large" }} fill gap="medium">
                                    <Box direction="row-responsive" pad={{ left: "medium", right: "medium" }} gap="large">
                                        <Box gap="small" fill alignSelf="center">
                                            <Heading level="3">{site.localizedStrings['ExternalPages.Certifications_Tab3_title']}</Heading>
                                            <Box fill>
                                                {parse(formatString(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Statement'],
                                                    [`${localUrl}/credentials`, `${localUrl}/documents/external/digital_badges.pdf `,
                                                    `${localUrl}/assistkit`]))}
                                            </Box>
                                        </Box>
                                        <Box fill pad={{ left: "small" }} alignSelf="center" >
                                            <Image fit="contain" src="images/technical-certifications/suggestion.jpg" />
                                        </Box>
                                    </Box>
                                    <Box background="#F5F5F5" pad={{ left: "medium", right: "medium" }} gap="small">
                                        <Box direction="row-responsive" gap="small" >
                                            <Box gap="small" align="center" width="medium" pad="small">
                                                <Box>
                                                    <Image src="images/hpe-image-1-200x200.jpg" />
                                                </Box>
                                                <Text weight="bold">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Step1']}</Text>
                                                <Paragraph fill textAlign="center">
                                                    {site.localizedStrings['ExternalPages.Certifications_Tab3_Step1_Statement']}
                                                </Paragraph>
                                            </Box>
                                            <Box gap="small" align="center" width="medium" pad="small">
                                                <Box>
                                                    <Image src="images/hpe-image-2-200x200.jpg" />
                                                </Box>
                                                <Text weight="bold">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Step2']}</Text>
                                                <Paragraph fill textAlign="center">
                                                    {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Step2_Statement'])}
                                                </Paragraph>
                                            </Box>
                                            <Box gap="small" align="center" width="medium" pad="small">
                                                <Box>
                                                    <Image src="images/hpe-image-3-200x200.jpg" />
                                                </Box>
                                                <Text weight="bold">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Step3']}</Text>
                                                <Paragraph fill textAlign="center">
                                                    {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Step3_Statement'])}
                                                </Paragraph>
                                            </Box>
                                            <Box gap="small" align="center" width="medium" pad="small">
                                                <Box>
                                                    <Image src="images/hpe-image-4-200x200.jpg" />
                                                </Box>
                                                <Text weight="bold">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Step4']}</Text>
                                                <Paragraph fill textAlign="center">
                                                    {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Step4_Statement'])}
                                                </Paragraph>
                                            </Box>
                                            <Box gap="small" align="center" width="medium" pad="small">
                                                <Box>
                                                    <Image src="images/technical-certifications/hpe-image-5-200x200.png" />
                                                </Box>
                                                <Text weight="bold">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Step5']}</Text>
                                                <Paragraph fill textAlign="center">
                                                    {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab4_Step5_Statement'])}
                                                </Paragraph>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Image src="images/technical-certifications/hpe-image-7-1600x400.jpg" />
                                        </Box>
                                    </Box>
                                </Box>
                            </Tab>
                            <Tab title={site.localizedStrings['ExternalPages.TechnicalCertifications_Tab5_Title']}>
                                <Box pad={{ top: "large", bottom: "small" }} gap="large" fill>
                                    <Box direction="row-responsive" pad={{ top: "medium" }} gap="medium">
                                        <Box fill alignSelf='center' gap="small">
                                            <Heading level="3">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab5_Section1_Title']}</Heading>
                                            <Paragraph size="large" fill>
                                                {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab5_Section1_Statement'])}
                                            </Paragraph>
                                            <Box gap='xsmall'>
                                                <Box direction="row-responsive" gap="small" align='center'>
                                                    <Text color="brand">
                                                        {site.localizedStrings['ExternalPages.TechnicalCertifications_Tab5_Section1_Link1']}
                                                    </Text>
                                                    <Box direction="row-responsive" gap="xsmall">
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00134453enw" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.English_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00134453ese" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Spanish_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://psnow.ext.hpe.com/doc/a00134453fre" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.French_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://psnow.ext.hpe.com/doc/a00134453dee" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.German_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://psnow.ext.hpe.com/doc/a00134453ptl" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Portuguese_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00134453jpn" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Japanese_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00134453kop" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Korean_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00134453zht" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.TraditionalChinese_Label']} color="brand" />
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" align='center'>
                                                    <Text color="brand">
                                                        {site.localizedStrings['ExternalPages.TechnicalCertifications_Tab5_Section1_Link2']}
                                                    </Text>
                                                    <Box direction="row-responsive" gap="xsmall">
                                                        <Anchor href="https://psnow.ext.hpe.com/doc/a00135199enw" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.English_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00135199dee" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.German_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00135199jpn" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Japanese_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00135199kop" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Korean_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00135199ptl" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Portuguese_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00135199spl" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Spanish_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://www.hpe.com/psnow/doc/a00135199zht" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.TraditionalChinese_Label']} color="brand" />
                                                    </Box>
                                                </Box>
                                                <Box direction="row-responsive" gap="small" align='center'>
                                                    <Text color="brand">
                                                        {parse(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab5_Section1_Link3'])}
                                                    </Text>
                                                    <Box direction="row-responsive" gap="xsmall" fill="horizontal">
                                                        <Anchor href="https://hpe.seismic.com/Link/Content/DCGMjqG4Gfh37GWF6pfdPM8CRTTV" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.English_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://hpe.seismic.com/Link/Content/DCFDMPg7B6G9h8TFP2fq26G2HJhd" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Spanish_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://hpe.seismic.com/Link/Content/DCM3qFX288B2MGWGDT7C8FpPDmJj" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.French_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://hpe.seismic.com/Link/Content/DCmBd6hhXV9b489VfRV4hQmGcFHP" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.German_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://hpe.seismic.com/Link/Content/DCXcF42CgpjRFG2W7D3G8RC6qRGB" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Portuguese_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://hpe.seismic.com/Link/Content/DCPMDpXb4bMVqGm2Q2FfT6H7MRWV" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Japanese_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://hpe.seismic.com/Link/Content/DCCjbg4Q9DHpm8TRjmDXjCf9QhPd" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.Korean_Label']} color="brand" />
                                                        <Text color="brand">|</Text>
                                                        <Anchor href="https://hpe.seismic.com/Link/Content/DC8pjdHQG7PC9GQBW3FGqbWddF2P" target="_blank"
                                                            label={site.localizedStrings['ExternalPages.TraditionalChinese_Label']} color="brand" />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box fill alignSelf="center">
                                            <Image src="images/technical-certifications/HPE202208310010041.jpg" />
                                        </Box>
                                    </Box>
                                    <Box direction="row-responsive" gap="large" pad="medium">
                                        <Box fill alignSelf="center">
                                            <Image src="images/technical-certifications/HPE_story_60.jpg" />
                                        </Box>
                                        <Box fill alignSelf='start' gap="small">
                                            <Heading level="3">{site.localizedStrings['ExternalPages.TechnicalCertifications_Tab5_Section2_Title']}</Heading>
                                            <Paragraph size="large" fill>
                                                {parse(formatString(site.localizedStrings['ExternalPages.TechnicalCertifications_Tab5_Section2_Statement'],
                                                    [`${localUrl}/certifications`]))}
                                            </Paragraph>
                                        </Box>
                                    </Box>
                                    <Text size="small">
                                        {site.localizedStrings['ExternalPages.TechnicalCertifications_Tab5_Section2_Footnote']}
                                    </Text>
                                </Box>
                            </Tab>
                        </Tabs>
                    </Box>}
                </BasePage>
            )
            }
        </ResponsiveContext.Consumer>;
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(TechnicalCertifications as any));
