import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../store';
import { KeyValuePair } from '../models/Common';
import { Box, Paragraph, Heading, Image, Accordion, AccordionPanel } from 'grommet';
import { formatString } from '../Utilities'
import { retrieveLocalizedStrings, siteSlice } from '../store/Site';
import { retrieveFaqs } from '../store/AssistKit';
import BasePage from '../components/BasePage';
import parse from 'html-react-parser';
import _ from 'lodash';
import { firstBy } from 'thenby';
import { FaqSection } from '../models/AssistKit/FaqSection';

const mapDispatchToProps = {
    retrieveLocalizedStrings,
    retrieveFaqs,
    ...siteSlice.actions,
}

interface IAssistKitProps {
}

interface AssistKitState {
}

type AssistKitProps = IAssistKitProps &
    ApplicationState // ... state we've requested from the Redux store
    & typeof mapDispatchToProps
    & RouteComponentProps<any>; // ... plus incoming routing parameters

class AssistKit extends React.PureComponent<AssistKitProps, AssistKitState> {
    constructor(props: AssistKitProps) {
        super(props);

        let urlLanguage = this.props.match
            ? this.props.match.params.lang
                ? this.props.match.params.lang
                : "en-US"
            : "en-US";
        let langInfo = urlLanguage.split('-');
        let keys = ['Metadata.AssistKit_Keywords,', 'Metadata.AssistKit_Description', 'ExternalPages.AssistKit_Statement', 'ExternalPages.AssistKit_Label'];

        this.state = {
        };
        this.props.retrieveLocalizedStrings(keys, langInfo[0]);
        this.props.retrieveFaqs(langInfo[0]);
        this.props.setActiveMenu('help-menu');
    }

    public componentDidMount() {
    }

    renderSection = (section: FaqSection, index: number) => {
        let site = this.props.site;
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let questions = _.orderBy(section.questions, (q: any) => q.sequence);
        let sections = _.orderBy(section.sections, (s: any) => s.sequence);

        return <AccordionPanel label={<Heading level='4' fill margin={{ vertical: "small" }}>{section.name}</Heading>} key={`section-${index}`} >
            <Box pad={{ left: "medium" }} gap="medium">
                {
                    questions.map((question: any, qndx: number) => {
                        let answer = question.answer;

                        while (/(.*)\/api\/(.*)\/.*?directory=(.*)(.)*\&fileName=(.*)/igm.test(answer)) {
                            answer = answer.replace(/(.*)\/api\/(.*)\/.*?directory=(.*)(.)*\&fileName=(.*)/igm, '$1/documents/$2/$3/$4$5');
                        }
                        while (/(\{0\})\/api\/(.*)\/(.*)\/Get\?directory=(.*)\&amp;filename=(.*)/igm.test(answer)) {
                            answer = answer.replace(/(\{0\})\/api\/(.*)\/(.*)\/Get\?directory=(.*)\&amp;filename=(.*)/igm, '$1/documents/$3/$4/$5');
                        }
                        while (/(http(s)*:\/\/certification-learning\.hpe\.com\/tr)\/api\/(Images)\/Get\?directory=(.*)(.)*\&fileName=(.*)/igm.test(answer)) {
                            answer = answer.replace(/(http(s)*:\/\/certification-learning\.hpe\.com\/tr)\/api\/(Images)\/Get\?directory=(.*)(.)*\&fileName=(.*)/igm,
                                `${localUrl}/documents/$3/$4/$6`);
                        }
                        while (/(\{0\})\/api\/(Images)\/Get\?directory=(.*)(.)*\&fileName=(.*)/igm.test(answer)) {
                            answer = answer.replace(/(\{0\})\/api\/(Images)\/Get\?directory=(.*)(.)*\&fileName=(.*)/igm, `$1/documents/$3/$4/$6`);
                        }

                        return <Box key={`section-${index}-question-${qndx}`} gap="xsmall">
                            <Box width={{ max: "xlarge" }}>{parse(question.question)}</Box>
                            <Box pad={{ left: "small" }} width={{ max: "xlarge" }} margin={{ top: "-15px" }}>
                                {parse(formatString(answer, [localUrl]))}
                            </Box>
                        </Box>
                    })
                }
                {
                    sections.map((sec: any, sndx: number) => {
                        return <Accordion key={`section-${index}-subsection-${sndx}`}>
                            {this.renderSection(sec, sndx)}
                        </Accordion>
                    })
                }
            </Box>
        </AccordionPanel>;
    }

    public render() {
        let site = this.props.site;
        let faqs = _.orderBy(this.props.assistKit.data, faq => faq.sequence);
        let langInfo = site.currentLanguage.value.split('-');
        let localUrl = langInfo[0] !== 'en'
            ? `${process.env.REACT_APP_MAIN_SITE}/${langInfo[0]}`
            : `${process.env.REACT_APP_MAIN_SITE}`;
        let breadcrumbs: KeyValuePair[] = [
            { key: site.menuStrings['Datacard.Overview_Label'], value: localUrl },
            { key: site.menuStrings['ExternalPages.Help_Label'], value: "" },
            { key: site.localizedStrings['ExternalPages.AssistKit_Label'], value: "" }
        ];
        let title = site.localizedStrings['ExternalPages.AssistKit_Label'];

        return <BasePage breadcrumbs={breadcrumbs} title={title} pageName="AssistKit" {...this.props}
            metadataKeywords={site.localizedStrings['Metadata.AssistKitKeywords']}
            metadataDescription={site.localizedStrings['Metadata.AssistKitDescription']} >
            {site.stringsLoaded && <Box gap="medium" >
                <Box gap="large" direction="row-responsive">
                    <Box fill alignSelf="center">
                        <Heading level={3} fill >{(title ?? "")}</Heading>
                        <Paragraph fill size="large">
                            {parse(site.localizedStrings['ExternalPages.AssistKit_Statement'])}
                        </Paragraph>
                    </Box>
                    <Box fill alignSelf="center">
                        <Image fit="contain" src="images/assist-kit/HPE202208310143554.jpg" />
                    </Box>
                </Box>
                <Box pad={{ bottom: "small" }}>
                    <Accordion>
                        {_.map(faqs, (section, ndx) => this.renderSection(section, ndx))}
                    </Accordion>
                </Box>
            </Box>}
        </BasePage>
    }
}

export default withRouter(connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    mapDispatchToProps)(AssistKit as any));
